.navbar-default {
	@include box-shadow(0 0 20px 5px rgba(0,0,0,.05));
	@include border-radius(0);
	@include transition(0.5s);
	background-color: $White;
	border: 0 none; 
	margin: 0;

	.navbar-nav {
 
		&.navbar-right {
			@include transition(0.5s);
			margin: 25px 0 0;
		} 

		li {
			position: relative;

			a {
				color: $sixth-color;
				font-weight: 700;
				padding: 10px 15px;
				position: relative;

				&:hover,
				&:focus {
					color: $sixth-color;
				    background: transparent;
				}

			}
			&.current_page_item {
				a {
					color: $sixth-color;
				    background: transparent;
				}
			}
			&.menu-item-has-children {

				&:hover,
				&:focus {
					.sub-menu {
						margin-top: 0;
					    opacity: 1;
					    visibility: visible;
					    
					}
				}
				.sub-menu {
					@include border-radius(0 0 3px 3px);
					@include transition(0.5s);
				    background-color: $sixth-color;
				    left: 0;
				    list-style: none;
				    min-width: 225px; 
				    margin-top: 10px;
				    opacity: 0;
				    padding: 10px 0;
				    position: absolute; 
				    text-align: left;
			    	visibility: hidden;
			    	z-index: 9999;

				    li {
				    	display: block;
				      	position: relative;
				      	a {
					        font-size: 13px;
					        margin: 0 15px;
					        color: $White;
					        display: block;
					        padding: 3px 0;
					        text-decoration: none;

					        &:hover,
						    &:focus { 
						        color: $White; 
						    }
				    	}
				    	&:first-child {
				          	a {
				            	border-top: 0 none;
				          	}
				        }
				        &:hover,
						&:focus {
							.sub-menu {
							    opacity: 1 !important;
							    top: -10px;
							}
						}
				        .sub-menu {
						    position: absolute;
						    top: 10px;
						    left: 100%;
						    opacity: 0 !important;
						}
						&:last-child {
							a {
								&:hover,
								&:focus {
									background-color: $second-color;
								}
							}
						}
				    }
				}
			}
			&.menu-item-has-children:hover,
		    &.menu-item-has-children:focus {
			    > .sub-menu {
			    	display: block;
			    }
		    }
		}
	}
	.navbar-toggle {
		@include transition(0.5s);
	    background-color: $second-color;
	    border: 2px solid $White;
	    height: 40px;
	    margin-top: 20px;
	    padding: 9px 8px;
	    width: 40px;
	    

	    &.collapsed {
	      border-color: $White;
	      border-style: solid;
	      border-width: 2px;
	      height: 40px;
	      padding: 9px 8px;
	      width: 40px;

	      .icon-bar {
	        background-color: $White;
	        text-align: center;
	        margin-left: 0;

	        &.bottom-bar,
	        &.top-bar {
	          transform: rotate(0);
	        }

	        &.middle-bar {
	          opacity: 1;
	        }
	      }
	    }

	    .icon-bar {
	      background-color: $White;
	      margin-left: 2px;
	      text-align: center;

	      &.bottom-bar,
	      &.top-bar {
	        @include transform-origin (10% 10%);
	        @include transition(0.5s);
	      }

	      &.middle-bar {
	        opacity: 0;
	      }

	      &.top-bar {
	        @include rotate (45deg);
	      }

	      &.bottom-bar {
	        @include rotate (-45deg);
	      }
	    }

	    &:hover,
	    &:focus {
	      background-color: $second-color;
	    }
	}

	.logo {
		@include transition(0.5s);
		padding: 10px 0;

		a {
			img {
				@include transition(0.5s);
				max-width: 308px;
			}
		}
	}

	// Scroll Effect
    &.fixed {
    	@include box-shadow(0px 5px 40px 0px rgba(0,0,0,.05));
        background-color: $White;
        left: 0px;
        right: 0px;
        position: fixed;
        top: 0;   
        z-index: 99999;

        .logo {
        	padding: 5px 0;

        	a {
        		img {
        			//width: 70%;
	        	}
        	}
        }

        .navbar-nav {
            li {
                a { 
                    &:hover,
					&:focus {
						color: $sixth-color;
				    	background: transparent;
					}
                }
                &.active,
				&.current_page_item {
					a {
						color: $sixth-color; 
				    	background: transparent;
					}
				}
            }

            &.navbar-right {
            	margin: 20px 0 0;  
            } 
        }
    }
    .navbar-header {
    	background-color: $White;
    }
}

/*
===============================================================
RESPONSIVE
===============================================================
*/
/* Large desktops and laptops */
@media (min-width: 1200px) { 
	
}


/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
	
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  	.navbar-default {
  		.navbar-collapse {
  			background-image: none;
  			padding: 0;
  		}
  		.navbar-nav {
  			&.navbar-right {
  				float: left !important;
  				margin: 0 !important;
  			}
  		}
  	}
}


/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
	// Navbar
    .navbar-default {
    	.navbar-toggle {
    		margin-top: 15px;
    	}

		.navbar-nav {
			display: block;
			float: none;
			margin: 0;

			li {

				&:first-child {
					border-top: 1px solid $White;
				}

				&.btn {
					margin-left: 0;
				}

				a {
					border-bottom: 1px solid $White;
					padding: 7px 0;
					text-align: center;

					&:before {
						display: none;
					}
				}

				&.menu-item-has-children { 
					.sub-menu {
						@include border-radius(0);
						background-color: transparent;
						border-bottom: 1px solid $White;
						border-top: 0 none;
						margin: 0;
						min-width: 100%;
						opacity: 1;
						padding: 0;
						position: relative;
						visibility: visible;
						li {
							&:first-child {
								border-top: 1px solid $White;
							}
							a {
								color: $Black;
							}
							&:last-child {
								a {
									background-color: transparent;
									border: 0 none;
								} 
							}
						}
    				}
				}
			}
		}
	    .navbar-collapse {
	    	background-color: $White;
	    	border-bottom: 20px solid $Black;
	      	left: 0;
	        margin-left: 0;
	      	margin-right: 0;
	      	max-height: none;
	      	opacity: .95;
	      	position: absolute;
	      	top: -10px;
	      	width: 100%;
	      	z-index: -1;

		   	.navbar-nav {
		        background-color: $White;
		        height: 100vh;
		        padding: 120px 15px 15px;

		        li {

		          	&.menu-item-has-children {
			            .sub-menu {
			              	background-color: transparent;
			              	margin: 0 auto;
			              	min-width: 85%;
			              	opacity: 1;
			              	padding: 0;
			              	position: relative; 
			              	text-align: center;
			              	visibility: visible;
			              	width: 85%;

			              	li {
				                a {
				                  color: $Black;
				                  padding: 7px 0;
				                }
				                &.active,
				                &.current_page_item {
				                  a {
				                    color: $White;
				                  }
				                }
			              	}
			            }
		          	}
		        }
	      	}
	    }


    	.logo {
    		padding: 10px 15px;

    	}

	    // Scroll Effect
	    &.fixed {
	    	.navbar-toggle {
	    		margin-top: 7px;
	    	}
			.navbar-header {
				@include box-shadow(0 0 5px 40px rgba(0,0,0,.05));
		    	background-color: $White;
		    }
		    .logo {
	    		padding: 5px 15px;
	    	}
		}    
    }
}


/* Landscape phones and smaller */
@media (max-width: 480px) {
    .navbar-default {
    	.logo {
    		a {
    			img {
    				max-width: 220px;
    			}
    		}
    	}
    }
}   
