@font-face {
    font-family: "DroidSans";
    src: url("../fonts/weblysleekuil-webfont.woff2") format("woff2"),
         url("../fonts/weblysleekuil-webfont.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "DroidSans";
    src: url("../fonts/droidsans-webfont.woff2") format("woff2"),
         url("../fonts/droidsans-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "DroidSans";
    src: url("../fonts/droidsans-bold-webfont.woff2") format("woff2"),
         url("../fonts/droidsans-bold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSans';
    src: url('../fonts/MuseoSans_500-webfont.eot');
    src: url('../fonts/MuseoSans_500-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/MuseoSans_500-webfont.woff') format('woff'),
         url('../fonts/MuseoSans_500-webfont.ttf') format('truetype'),
         url('../fonts/MuseoSans_500-webfont.svg#MuseoSans500Regular') format('svg');
    font-weight: 500;
    font-style: normal;
} 

