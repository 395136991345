// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$Black: 			#000000;
$White: 			#ffffff;

$first-color:       #7ac247;
$second-color:      #27aae1;
$third-color:       #ec1b4c;
$fourth-color:      #f58123;
$fifth-color:       #fcde05;
$sixth-color:       #1f5a82;

// Custom Button
$btn-default-color:      $White !default;
$btn-default-bg:         $second-color !default;
$btn-default-border:     $second-color !default;
