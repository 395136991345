.blog {
	#main {
		.content {
			article {
				border: 1px solid $Black;
				margin: 0 0 25px;
    			padding: 15px;

    			h3 {
    				margin-top: 0;
    			}
			}
		}
	}
}