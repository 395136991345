#featured {
	background-color: $White;
	padding: 15px 0 75px; 

	ul {
		list-style: none;
	    padding: 0;
	    margin: 0 -15px;

	    li {
	    	display: inline-block;
	    	padding: 0 15px;
	    	width: 33%;
	    }
	}

	.feature {
		margin: 0 0 25px;
		
		h3 {
		    color: $second-color;
		    font-size: 20px;
		    text-transform: uppercase;
		    margin-top: 25px;
		} 

		p {
			line-height: 26px; 
		}

		.icon-feature {
			@include transition(0.5s);
			height: auto;
			margin: 0 auto;
			overflow: hidden;
			width: 100%;

			img {
				margin: 0 auto;
				max-width: 100%;
			} 
		}
	}
}


/*
===============================================================
RESPONSIVE
===============================================================
*/
/* Large desktops and laptops */
@media (min-width: 1200px) { 

}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
   	
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
   	#featured {
		ul {
		    li {
		    	width: 32%;
		    }
		}
	}
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
   	#featured {
   		ul {
		    li {
		    	width: 100%;
		    }
		}
		.feature {
			margin: 0 0 45px;
		}
	}
}

/* Landscape phones and smaller */
@media (max-width: 480px) {
    
}   
