#googleMap { 
    min-height: 350px;
    width: 100%;
}




/*
===============================================================
RESPONSIVE AREA
===============================================================
*/

/* Large desktops and laptops */
@media (min-width: 1200px) {
   
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
    #googleMap { 
	    min-height: 320px;
	}
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
   	#googleMap { 
	    min-height: 280px;
	}
}
 
/* Landscape phones and smaller */
@media (max-width: 480px) {
    
}
