// Grid system
.wrap {
	margin-left: -15px;
	margin-right: -15px;
	.content {
	  @include make-sm-column($main-sm-columns);
	  .sidebar-primary & {
	    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
	  }
	}
	.sidebar {
	  @include make-sm-column($sidebar-sm-columns);
	}
}


