/*--------------------------------------------------------------
# Responsive mixins
--------------------------------------------------------------*/

@mixin breakpoint($class) {
    @if $class==xs {
        @media (max-width: 543px) {
            @content;
        }
    }
    @else if $class==sm {
        @media (max-width: 767px) {
            @content;
        }
    }
    @else if $class==md {
        @media (max-width: 991px) {
            @content;
        }
    }
    @else if $class==lg {
        @media (max-width: 1199px) {
            @content;
        }
    }
    @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
}


/*--------------------------------------------------------------
# Transition mixins
--------------------------------------------------------------*/

@mixin transition($transition){
  -webkit-transition: $transition;
     -moz-transition: $transition;
      -ms-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}

@mixin transition-delay($delay) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

@mixin border-radius($radius){
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
       -o-border-radius: $radius;
          border-radius: $radius;
}

@mixin grayscale($percentage){
  -webkit-filter: grayscale($percentage);
     -moz-filter: grayscale($percentage);
      -ms-filter: grayscale($percentage);
       -o-filter: grayscale($percentage);
          filter: grayscale($percentage);
}

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
     -moz-transform: translate3d($x, $y, $z);
       -o-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}

// generic transform
@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}
 
// scale
@mixin scale($scale) {
   @include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
       -o-transform-origin: $origin;
      -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
          transform-origin: $origin;
}