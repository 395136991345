.carousel {
    color: $White;
    overflow: hidden;
    margin: 85px 0 0;
    padding: 0 0 15px;
    position: relative;
 
    .carousel-inner {
        .item {
            background-position: top 20% center;
            background-repeat: no-repeat;
            background-size: cover;

            h2 { 
                color: $White;
                font-weight: 700;
                font-size: 65px;
                margin-bottom: 25px; 
            }

            p {
                color: $White;
                font-size: 19px;
                line-height: 26px;
                font-weight: 100;
            }

            .btn {
                @include border-radius(50px);
                @include transition(0.5s);
                border: 1px solid $White;
                color: $White;
                margin-right: 20px; 
                margin-top: 25px;
                padding: 15px 27px;
                text-transform: uppercase;

                &:hover,
                &:focus {
                    background-color: $second-color;
                    color: $White;
                    border-color: transparent;
                    text-decoration: none;
                }
            }
        }
    }
}


/*
===============================================================
RESPONSIVE
===============================================================
*/
/* Large desktops and laptops */
@media (min-width: 1200px) { 
    .carousel {
        .carousel-inner {
            .item {
                padding: 90px 0 60px;
            }
        }
    }
}


/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .carousel {
        .carousel-inner {
            .item {
                padding: 90px 0 60px;
            }
        }
    }
}


/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
    .carousel {
        margin: 75px 0 0;
        .carousel-inner {
            .item {
                padding: 80px 0 60px;

                h2 {
                    font-size: 60px;
                }
                p {
                    font-size: 16px;
                }
                .btn {
                    padding: 10px 25px;
                }
            }
        }
    }
}


/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
   .carousel {
        margin: 70px 0 0;
        .carousel-inner {
            .item {
                padding: 70px 0 45px;

                h2 {
                    font-size: 60px;
                    width: 100%;

                    span {
                        display: block;
                        position: relative;
                    }
                }
                p {
                    font-size: 16px;
                    width: 100%;
                }
                .btn {
                    padding: 10px 25px;
                }
            }
        }
    }
}


/* Landscape phones and smaller */
@media (max-width: 480px) {
    .carousel {
        .carousel-inner {
            .item {
                padding: 15px 0 25px;
            }
        }
    }
}   


@media (max-width: 375px) {
    .carousel {
        .carousel-inner {
            .item {
                .carousel-caption {
                    h2 {
                        font-size: 48px;
                        br {
                            display: none;
                        }
                    }
                    .btn {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}   