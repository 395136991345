#footer {
	background-color: $sixth-color;
	color: $White;

	p {
		border-top: 1px solid $White;
		margin: 0;
		padding: 25px 0;
	}
} 


/*
===============================================================
RESPONSIVE
===============================================================
*/
/* Large desktops and laptops */
@media (min-width: 1200px) { 

}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
   	
} 

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
   	
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
   	
}

/* Landscape phones and smaller */
@media (max-width: 480px) {
    
}   
