.breadcrumbs {
    display: inline-block;
    color: $White;
    padding: 6px 15px 4px;
    background-color: $Black;
    margin: 0 0 15px;

    a {
	    color: $White;

	    &:hover,
	    &:focus {
	    	color: $White;
	    }
	}
}