.home {
	#main {
		min-height: auto;
		padding: 15px 0 35px;
		
		.content {
			h2 {
				
			}
		}
	}
}