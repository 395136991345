// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

 
form {

  input[type=text],
  input[type=email],
  input[type=tel],
  input[type=url],
  input[type=number],
  input[type=password],
  textarea,
  select {
    background: $White;
    border: 1px solid $Black;
    border-radius:  4px;
    box-shadow: none !important;
    display: block;
    height: 40px; 
    line-height: 40px;
    margin: 0 0 15px;
    outline: medium none !important;
    padding: 0 5px !important;
    width: 100% !important;
    
    &:hover,
    &:focus {
      border: 1px solid $Black;
      box-shadow: none !important;
      outline: medium none !important;
    }

  }

  input {
    &[type=number] {
      display: inline-block;
      padding: 0;
    }
    &[type=submit] {
      @extend .btn;
      @extend .btn-default;
    }
  }

  textarea {
    min-height: 100px !important;
    padding: 0 10px !important;
  }

} 

@media only screen and (min-width : 320px) {}

@media only screen and (min-width : 480px) {}

@media only screen and (min-width : 600px) {}

@media only screen and (min-width : 768px) {
  form {

    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=number],
    input[type=password],
    textarea,
    select {
      height: 40px;
      line-height: 40px;
      margin: 0 0 15px; 
    }

    select {
      height: 40px;
    }

    textarea {
      min-height: 200px;
    }
  }
}

@media only screen and (min-width : 992px) {
  form {

    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=number],
    input[type=password],
    textarea,
    select {
      height: 40px;
      line-height: 40px;
      margin: 0 0 15px;
    }

    select {
      height: 40px;
    }

    textarea {
      min-height: 300px;
    }
  }
}

@media only screen and (min-width : 1200px) {}


