#main {
	margin: 45px 0 0;
	min-height: 350px;
	padding: 95px 0 45px;

	.page-header {
		margin: 0 0 20px;
		padding: 0;

		h1 {
			color: $second-color;
		}
	}

	.content {
		img {
			border: 1px solid $Black;
    		padding: 5px;
		}
	}

	h2 {
		text-transform: uppercase;
	}
	
	h3 {
		text-transform: uppercase;
	}
	.page_thumb {
		float: left;
		margin-bottom: 15px;
		margin-right: 25px;
		margin-top: 5px;
	}
	.gallery_list {
		.item {
			margin: 0 0 30px;
		}
	}
} 

/*
===============================================================
RESPONSIVE
===============================================================
*/
/* Large desktops and laptops */
@media (min-width: 1200px) { 

}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
   	
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
   	
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
   	#main {
		margin: 25px 0 0;
		padding: 75px 0 35px;
	}
}

/* Landscape phones and smaller */
@media (max-width: 480px) {
    
}   
