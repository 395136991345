body {
	color: $Black;
	font-family: "DroidSans";
	font-size: 14px; 
	font-wieght: 400;
	letter-spacing: 0.5px;
	line-height: 1.8;

	a {
		color: $sixth-color;
		ouline: 0 !important;
		text-decoration: none !important;
		text-transform: none !important;
		
		&:hover,
		&:focus {
			color: $sixth-color;
			ouline: 0 !important;
			opacity: 0.9;
		}
	}

}