.sidebar {
	.widget {
		&:first-child {
			h3 {
				margin-top: 0;
			}
		}
		
		ul {
			padding-left: 15px;
		}
	}
}