#slogan {
	background-color: $second-color;
	color: $White;
	padding: 20px 0 25px;

	h3 {
	    color: $White;
	    text-transform: uppercase;
	    font-size: 28px;
	    margin-top: 20px;
	}

	.btn {
		@include border-radius(50px);
		background-color: transparent;
		border-color: $White;
		color: $White;
		display: block;
		font-size: 15px;
		margin: 25px 0 0;
		padding: 12px 25px;
		text-transform: uppercase;

		&:hover,
		&:focus {
			color: $White;
		}
	}
}