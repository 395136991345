.gform_wrapper {
	form {
		padding: 0;
	    position: relative;

	    &:before {
	    	display: none;
	    }

		ul {
			list-style: none;
			padding: 0;
		}

		&.offerte {
			.gform_button {
				margin-left: 15px;
				margin-top: 10px;
			}
		}
	}
	.top_label {
		.gfield_label {
			display: block;
		}
	}
}


/*
===============================================================
RESPONSIVE
===============================================================
*/
/* Large desktops and laptops */
@media (min-width: 1200px) { 

}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
   	
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
   	
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
   	.gform_wrapper {
		form {
			&.offerte {
				margin: 0 0 25px;

				.gform_body {
					ul {
						li {
							padding: 0;
						}
					}
				}
				.gform_button {
					margin-left: 0;
					margin-top: 5px;
				}
			}
		}
	}	
}

/* Landscape phones and smaller */
@media (max-width: 480px) {
    
}   