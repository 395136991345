#footer_widget {
	background-color: $sixth-color;
	color: $White;
	line-height: 28px;
	padding: 45px 0 35px;

	a {
		color: $White;

		&:hover,
		&:focus {
			color: $White;
		}
	}

	ul {
		list-style: none;
	    margin: 0;
	    padding: 0;

	    li {
	    	a {
	    		color: $White;
		    	position: relative;

		    	&:hover,
				&:focus { 
					color: $White;
				}
	    	}
	    }
	}

	.social_media {
		margin: 15px 0 0;
		ul {
			li {
				float: left; 
			    height: 40px;
			    width: 50px;
				a {
					border: 1px solid $White;
					color: $White;
					display: block;
					margin-right: 7px;
					padding: 7px;
	    			text-align: center; 

					&:hover,
					&:focus {
						border-color: $Black;
						color: $White;
					}
				}
			}
			&:after {
				content: "";
				clear: both;
			}
		}
	}


	h3 {
	    margin-top: 0;
	    color: $White;
	    font-size: 22px;
	    margin-bottom: 25px;
	}
}


/*
===============================================================
RESPONSIVE
===============================================================
*/
/* Large desktops and laptops */
@media (min-width: 1200px) { 

}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
   	
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
	#footer_widget {
		h3 {
		    margin-bottom: 10px;
		    margin-top: 20px;
		}
	}
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {	
	#footer_widget {
		h3 {
		    margin-bottom: 10px;
		    margin-top: 20px;
		}
	}
}

/* Landscape phones and smaller */
@media (max-width: 480px) {
    
}   