button,
.btn  {
    @include border-radius(50px);
    box-shadow: none !important;
    color: $White;
    font-weight: 600;

    margin-top: 10px;
    outline: none !important;
    padding: 10px 20px;
    text-transform: uppercase;
    white-space: normal;

    &:hover,
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }   
       
    &.btn-default {
        @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
    }
}